import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ScheduleUpload, IdrSource } from './schedule-upload';
import { ScheduleUploadService } from './schedule-upload.service';
import * as _ from 'lodash';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { errorMonitor } from 'events';

import { MatDialog } from '@angular/material/dialog';
import { SpinnerService } from '../spinner.service';
import { RunDialogComponent } from '../run-dialog/run-dialog.component';
import Swal from 'sweetalert2';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-schedule-upload',
  templateUrl: './schedule-upload.component.html',
  styleUrls: ['./schedule-upload.component.css'],
  // encapsulation: ViewEncapsulation.None,
})
export class ScheduleUploadComponent implements OnInit {
  componentTitle: string = "Batch Process - Schedule & Upload";
  displayedColumns: string[] = ['name', 'type', 'frequency', 'mailto', 'lastdata', 'lastrun', 'action'];
  dataSource = new MatTableDataSource<any>();
  scheduleUploadForm: UntypedFormGroup;
  scheduleUpload: ScheduleUpload = new ScheduleUpload();
  data: ScheduleUpload[];
  idrSources: IdrSource = new IdrSource();
  message: string = 'Error';
  action: string = "Close";
  mailVal: string = '';

  public frequencies = [{ name: "30 mins", value: 30 }, { name: "1 hr", value: 60 }, { name: "2 hrs", value: 2 }, { name: "3 hrs", value: 3 }, { name: "5 hrs", value: 5 }, { name: "12 hrs", value: 12 }, { name: "24 hrs", value: 24 }];
  frequencyValue: any;
  whereIsFrom: string;
  successData: string;
  constructor(
    private scheduleService: ScheduleUploadService,
    private fb: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.scheduleUploadForm = this.fb.group({
      scheduleUploads: this.fb.array([])
    })
    this.getScheduleData();
  }

  getScheduleData(): void {
    this.scheduleService.getAllAsFormArray().subscribe(scheduleUploads => {
      this.scheduleUploadForm.setControl('scheduleUploads', scheduleUploads);
      this.dataSource = new MatTableDataSource(scheduleUploads.value);
    })
  }

  get scheduleUploads(): UntypedFormArray {
    return this.scheduleUploadForm.get('scheduleUploads') as UntypedFormArray;
  }


  onSelectFreq(event) {
    if (event.value) {
      if (event.value == 60) {
        var result = "1 hr"
        this.frequencyValue = result;
      } else if (event.value == 30) {
        var result = "30 mins"
        this.frequencyValue = result;

      } else if (event.value == 3) {
        var result = "3 hrs"
        this.frequencyValue = result;
      }
      else if (event.value == 5) {
        var result = "5 hrs"
        this.frequencyValue = result;
      } else if (event.value == 12) {
        var result = "12 hrs"
        this.frequencyValue = result;
      } else if (event.value == 24) {
        var result = "24 hrs"
        this.frequencyValue = result;
      }
    }
    this.idrSources.idr_source.frequency = this.frequencyValue;
  }

  onSave(mail, frequency, id) {
    this.idrSources.idr_source.mail_to = mail;
    if (this.frequencyValue) {
      this.idrSources.idr_source.frequency = this.frequencyValue;
    } else {
      this.idrSources.idr_source.frequency = frequency;
    }


    this.scheduleService.saveData(this.idrSources, id).subscribe(res => {
      this.successData = "You Saved Successfully";
      this.successNotification();
    },
      error => {
        this.successData = error.error.message;
        this.errorNotification()
        // this._snackBar.open(this.message, this.action, {
        //   duration: 2000,
        // });
      }
    )
  }
  successNotification() {
    Swal.fire({
      icon: 'success',
      text: this.successData,
      width: '32em'
    })
  }

  errorNotification() {
    Swal.fire({
      icon: 'error',
      text: this.successData,
    })
  }

  openRunDialog(data, height, width): void {
    const dialogRef = this.dialog.open(RunDialogComponent, {
      width: width,
      height: height,
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  ifFileorApi(element, source) {
    sessionStorage.setItem("Source", source);
    if (element == 'api') {
      this.whereIsFrom = "api"
      let height = '25em';
      let width = '45em'
      this.openRunDialog(this.whereIsFrom, height, width);
    } else {
      this.whereIsFrom = "file"
      let height = '11em';
      let width = '25em'
      this.openRunDialog(this.whereIsFrom, height, width);
    }
  }


}