import { DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import Swal from 'sweetalert2';
import { ScheduleUploadService } from '../schedule-upload/schedule-upload.service';
import { behaviour } from 'src/environments/behaviour';
import { timeout, catchError } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-run-dialog',
  templateUrl: './run-dialog.component.html',
  styleUrls: ['./run-dialog.component.css']
})
export class RunDialogComponent implements OnInit {
  fromDate: string;
  endDate: string;
  salseForceId: string;
  date: any;
  successData: string;
  errorData: string;
  fromData: boolean;
  uploadForm: UntypedFormGroup;
  selectedSource: string = '';
  uploadApi: string = '';

  @ViewChild('fileInput', { static: false }) fileInput;
  file: File | null = null;
  fileName: any;

  constructor(
    public scheduleService: ScheduleUploadService,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    public dialogRef1: MatDialogRef<RunDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public inputParam: any,
    public dialogRef: MatDialogRef<RunDialogComponent>,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {

    this.selectedSource = sessionStorage.getItem("Source");
    this.selectedSource = this.selectedSource.toLowerCase();
    this.uploadForm = this.formBuilder.group({
      profile: ['', Validators.pattern('^[a-zA-Z0-9._-]+$')]
    });
    this.showPopup();
    this.uploadApi = behaviour.sourcesConfig[this.selectedSource].batchApi;
  }

  onFileSelect(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileName = event.target.files[0].name;
      this.uploadForm.get('profile').setValue(file);
      this.onSubmit();
    }

  }

  onClickFileInputButton(): void {
    this.fileInput.nativeElement.click();
  }

  onSubmit() {
    const formData = new FormData();
    formData.append('file_content', this.uploadForm.get('profile').value);
    // formData.append('source', this.fileName);
    this.scheduleService.uploadData(formData,).subscribe(res => {
      this.successData = res.message;
      this.successNotification();
    },
      error => {
        this.successData = error.error.message;
        this.errorNotification()
      }
    )
  }
  execute() {
    const formData = new FormData();
    formData.append('file_content', this.uploadForm.get('profile').value);
    this.scheduleService.executeData(formData, this.fileName, this.selectedSource, this.uploadApi).pipe(timeout(10000)).subscribe(res => {
      this.successData = res.message;
      this.dialogRef1.close();
      this.successNotification()
    },
      error => {
        if (error.message == "Timeout has occurred") {
          this.successData = "This batch is taking a long time to respond. Please check the batch list page at a later time to see the results of this batch. Thank you";
          this.errorNotification()
        } else {
          this.successData = error.error.message;
          this.errorNotification()
        }

        // }
        //     else {
        //   const formData = new FormData();
        //   formData.append('file_content', this.uploadForm.get('profile').value);
        //   // formData.append('source', this.fileName);
        //   this.scheduleService.uploadData(formData).subscribe(res => {
        //     this.dialogRef1.close();
        //     this.successData = res.message;
        //     this.successNotification()
        //     console.log('res',res)
        //   },
        //   error => {
        //     this.successData = error.error.message;
        //     this.dialogRef1.close();
        //     this.errorNotification()
        //     console.log('error', error);
        //   }
        //   )
        // }

      }
    )
  }

  showPopup() {
    if (this.inputParam === 'api') {
      this.fromData = true;
    } else if (this.inputParam === 'file') {
      this.fromData = false;
    }
  }

  startDateChange(eve) {
    let date = eve.value._d;
    this.fromDate = this.datepipe.transform(date, 'yyyy-MM-dd' + 'T' + 'hh:mm:ss-0000')
  }
  endDateChange(eve) {
    let date = eve.value._d;
    this.endDate = this.datepipe.transform(date, 'yyyy-MM-dd' + 'T' + 'hh:mm:ss-0000')
  }

  checkDisabled(){
    if(this.fromDate && this.endDate){
      return false;
    }else if(this.salseForceId){
      return false;
    }else{
      return true;
    }
  }

  executeData() {
    this.scheduleService.runService(this.salseForceId, this.fromDate, this.endDate, this.uploadApi).pipe(timeout(10000)).subscribe(res => {
      this.dialogRef1.close();
      this.successData = "Batch is successfully running";
      this.successNotification();
    },
      error => {
        if (error.message == "Timeout has occurred") {
          this.successData = "This batch is taking a long time to respond. Please check the batch list page at a later time to see the results of this batch. Thank you";
          this.dialogRef1.close();
          this.errorNotification()
        } else {
          this.successData = error.error.message;
          this.dialogRef1.close();
          this.errorNotification();
        }

      }
    )
  }
  successNotification() {
    // Swal.fire( this.successData, 'success')
    Swal.fire({
      icon: 'success',
      text: this.successData,
    })
  }

  errorNotification() {
    Swal.fire({
      icon: 'error',
      text: this.successData,
    })
  }

  error() {

  }

}
