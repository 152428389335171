import { Component, OnInit, HostBinding } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'CrmIa';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }
  ngOnInit() {
    let sessionAuth;
    sessionAuth = sessionStorage.getItem("loginCookie");
    let clientId = environment.clientId;
    let ssoURL = 'https://login.windows.net/704ce3d6-a4bf-4e09-8516-d52840c9f7a9/oauth2/v2.0/authorize'
    let scope = 'openid';
    let responseType = 'token';
    
    let responseMode = 'fragment';
    let state = '12345';
    let nonce = '678910';
    let returnURL = encodeURI(environment.clientUrl);
    let myUrl = `${ssoURL}?client_id=${clientId}&scope=${scope}&response_type=${responseType}&redirect_uri=${returnURL}&response_mode=${responseMode}&state=${state}&nonce=${nonce}`;
    let currentUrl = window.location.href;
    if (sessionAuth) {
      // this.router.navigateByUrl('/batch-match');
      //this.getAuthenticationType();
    } else {
      if (currentUrl.includes('#access_token')) {
        var accessToken = this.getParameterByName('access_token', currentUrl);
        sessionStorage.setItem("loginCookie", accessToken);
        this.router.navigateByUrl('/batch-match');
      } else {
        window.location.href = myUrl;

      }

    }
    // If maintenance window is acting up, only use this method.
    // this.getAuthenticationType();
  }
  getParameterByName(name, url) {
    name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    var regex = new RegExp("[\\?&#]" + name + "=([^&#]*)"),
      results = regex.exec(url);
    return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  }
  gotoPages(string) {
    if (string == 'bW') {
      this.router.navigateByUrl('/batch-match');
    } else if (string == 'Pu') {
      this.router.navigateByUrl('/profile-updates');
    } else if (string == 'sPr') {
      this.router.navigateByUrl('/contacts');
    } else if (string == 'Su') {
      this.router.navigateByUrl('/schedule-upload');
    }
  }
}
