<div class="container-fluid">
    <h1>BATCH PROCESS - VIEW DETAILS</h1>
    <div class="row">
      <div class="col-md-10">
      Batch Id : {{batchId}}
    </div>
    <div class="col-md-2">
      <button mat-raised-button  color="primary" mat-dialog-close (click)="goBack()">Back to Batch & Match</button>
    </div>
  </div>
    <div>Total # of Records : {{totalElem}}  </div>
    
      <mat-form-field appearance="fill" class="field_pad_right">
        <mat-label>Select Status</mat-label>
      <mat-select (selectionChange)="fetchRecord($event)" >
        <mat-option [value]=0 selected>Show All</mat-option>
        <mat-option [value]=2 >Successful</mat-option>
        <mat-option [value]=3 >Multiple Matches</mat-option>
        <mat-option [value]=4 >No Match</mat-option>
        <mat-option [value]=5 >Pending Verification</mat-option>
      </mat-select>
      </mat-form-field>
   
    
    <div class="row">
        <div class="col-md-12 col-12">
          <div class="faculty-summary-container mat-elevation-z2">
            <table mat-table [dataSource]="dataSource" (matSortChange)="onSortData($event)" matSort>
              <ng-container matColumnDef="first_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
                <td mat-cell *matCellDef="let element" (click) = "openDetail(element)"  [innerHTML]="getName(element)">  </td>
              </ng-container>
    
              <ng-container matColumnDef="last_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
                <td mat-cell *matCellDef="let element" (click) = "openDetail(element)" > {{element.last_name}} </td>
              </ng-container>
    
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                <td mat-cell *matCellDef="let element" (click) = "openDetail(element)"> {{element.email }} </td>
              </ng-container>
    
              <ng-container matColumnDef="imported">
                <th mat-header-cell *matHeaderCellDef> SF ID </th>
                <td mat-cell *matCellDef="let element" class="cursor_pointer" (click)="navSales(element)"> {{element.matched_record_id}} <span *ngIf="element.matched_record_id != null"><img src="./assets/images/sflogo.jpeg"></span></td>
              </ng-container>

              <ng-container matColumnDef="recon">
                <th mat-header-cell *matHeaderCellDef> Recon Status </th>
                <td mat-cell *matCellDef="let element" class="cursor_pointer"> 
                  <span *ngIf="element.reconciled != 4">{{getReconDefinition(element.reconciled,'')}}</span>
                  <span *ngIf="element.reconciled == 4"><a (click)="OpenSearchPage()">No Match</a></span></td>
              </ng-container>
             
              <ng-container matColumnDef="saved">
                <th mat-header-cell *matHeaderCellDef>  </th>
                <td mat-cell *matCellDef="let element" class="cursor_pointer">
                  <!-- <button mat-raised-button color="primary" mat-dialog-close>{{getReconDefinitionBtn(element.reconciled)}}</button> -->
                  <button mat-raised-button  color="primary" mat-dialog-close *ngIf="element.reconciled == 2" (click)="updateRecords(element)">View</button>
                  <button mat-raised-button color="secondary" mat-dialog-close *ngIf="element.reconciled == 3" (click)="updateRecords(element)">Recon</button>
                  <button mat-raised-button color="secondary" mat-dialog-close *ngIf="element.reconciled == 5" (click)="updateRecords(element)">Recon</button>
                </td>
              </ng-container>
              
    
    
    
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;">
              </tr>
            </table>
          </div>
        </div>
      </div>
    
      <div class="row">
        <div class="col-12">
          <mat-paginator  [pageSize]="rowsPerPage"
            showFirstLastButtons [length]="totalElem" (page)="onPagination($event)" showFirstLastButtons
            id="admin-audit-history-pagination"></mat-paginator>
        </div>
      </div>
</div>
