import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ScheduleUploadComponent } from "./schedule-upload.component";

export class ScheduleUpload {
    created_at: string;
    display_name: string;
    frequency: any;
    id: number;
    last_run: string;
    mail_to: string;
    model: string;
    name: string;
    restricted: boolean;
    schedulable: boolean;
    source_type: string;
    updated_at: string;

    static asFormGroup(scheduleUpload: ScheduleUpload): UntypedFormGroup {
        const fg = new UntypedFormGroup({
            created_at: new UntypedFormControl(scheduleUpload.created_at),
            display_name: new UntypedFormControl(scheduleUpload.display_name),
            frequency: new UntypedFormControl(scheduleUpload.frequency,Validators.required),
            id: new UntypedFormControl(scheduleUpload.id),
            last_run: new UntypedFormControl(scheduleUpload.last_run),
            mail_to: new UntypedFormControl(scheduleUpload.mail_to),
            model: new UntypedFormControl(scheduleUpload.model),
            name: new UntypedFormControl(scheduleUpload.name),
            restricted: new UntypedFormControl(scheduleUpload.restricted),
            schedulable: new UntypedFormControl(scheduleUpload.schedulable),
            source_type: new UntypedFormControl(scheduleUpload.source_type),
            updated_at: new UntypedFormControl(scheduleUpload.updated_at)
          });
          return fg;
    }
}

export class IdrSource {
    idr_source:  IdrData = new IdrData();
  }

  export class IdrData {
    mail_to: string;
    frequency: number;
  }