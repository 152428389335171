import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
//import { TestServiceService } from './test-service.service';
import { ProfileDetailService } from './profile-details.service';

import { UpdateResponse, Updates } from './profile-detail';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
import { MatPaginator } from '@angular/material/paginator';
export class PaginationParam {
  page: number;
  size: number; //number of rows per page

  constructor(pageNbr: number, noOfItems: number) {
    this.page = pageNbr;
    this.size = noOfItems;
  }
}
@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.css']
})
export class ProfileDetailsComponent implements OnInit {
  firstName: string = '';
  lastName: string = '';
  pageSizeOptions: number[] = [];
  totalElem: number = 5;
  displayedColumns: string[] = ['source', 'fname', 'lname', 'email', 'lastrun', 'field', 'old', 'new', 'used', 'select'];
  //dataSource = ELEMENT_DATA;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = new MatTableDataSource<Updates>();
  selection = new SelectionModel<Updates>(true, []);
  allRecords: Updates[] = [];
  fromDate: Date;
  endDate: Date;
  sources: any[] = [];
  selectedSource: any = 0;
  rowsPerPage: number = 20;
  source = new UntypedFormControl();
  pageNumber: number = 1;
  salesforceId: string = '';
  email: string = '';
  toppingList: string[] = ['source', 'fname', 'lname', 'email ', 'lastrun', 'field', 'old', 'new', 'used', 'select'];
  sub: any;
  constructor(
    private profileDetailService: ProfileDetailService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.sub = this.route.queryParams.subscribe(params => {
      if (params['salesforce_id']) {
        this.salesforceId = params['salesforce_id'];
      }
    });
    //this.dataSource = new MatTableDataSource(ELEMENT_DATA);
    let params: PaginationParam = new PaginationParam(1, this.rowsPerPage);
    this.getSources();
    this.fetchTableRecords(params);
    //this.pageSizeOptionsCalculation(ELEMENT_DATA.length)
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
  checkboxLabel(row?: Updates): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row `;
  }
  getSources() {
    this.profileDetailService.getSourceData().subscribe(data => {
      this.sources = data;
    })
  }
  navSales() {
    if(this.salesforceId){
      let url = 'https://umuc.lightning.force.com/lightning/r/Contact/' + this.salesforceId + '/view'
    window.open(url, "_blank");
    }
    
  }
  fetchRecord(eve) {
    this.selectedSource = eve.value.name;
    let params: PaginationParam = new PaginationParam(this.pageNumber, this.paginator.pageSize);
    this.fetchTableRecords(params);
  }
  startDateChange(eve) {
    this.fromDate = eve.value._d;
    let params: PaginationParam = new PaginationParam(this.pageNumber, this.paginator.pageSize);
    this.fetchTableRecords(params);
  }
  endDateChange(eve) {
    this.endDate = eve.value._d;
    let params: PaginationParam = new PaginationParam(this.pageNumber, this.paginator.pageSize);
    this.fetchTableRecords(params);
  }
  fetchTableRecords(params) {
    //let params: PaginationParam = new PaginationParam(this.pageNumber, this.paginator.pageSize);

    this.profileDetailService.getTableRecords(this.selectedSource, this.salesforceId, params).subscribe(data => {
      this.firstName = data.person.first_name;
      this.lastName = data.person.last_name;
      this.email = data.person.email;
      this.dataSource = new MatTableDataSource(data.records);
      this.allRecords = data.records;
      this.totalElem = data.totalCount;
      this.pageSizeOptionsCalculation(data.totalCount);
    })
  }
  pageSizeOptionsCalculation(total: number) {
    const defaultPageArray: number[] = [5, 10, 25, 50, 100, 250, 500, total];
    this.pageSizeOptions = [];
    this.pageSizeOptions = defaultPageArray.filter(x => x <= total);
  }
  onPagination(event: any) {
    this.pageNumber = event.pageIndex + 1;
    let params: PaginationParam = new PaginationParam(this.pageNumber, this.paginator.pageSize);
    this.fetchTableRecords(params);
  }
  openDetail(elem) {
    this.router.navigateByUrl('/batch-details');
    localStorage.setItem('selectedBatch', JSON.stringify(elem));
  }
  updateRecords() {
    var presents = _.intersectionWith(this.allRecords, this.selection.selected, _.isEqual);
    var diff = _.differenceWith(this.allRecords, this.selection.selected, _.isEqual);
    var updateObj = [];
    if (presents.length > 0) {
      _.each(presents, function (school) {
        let arr = {
          id: JSON.stringify(school.id),
          status: 1
        }
        updateObj.push(arr);
      });
    }
    if (diff.length > 0) {
      _.each(diff, function (school) {
        let arr = {
          id: JSON.stringify(school.id),
          status: 2
        }
        updateObj.push(arr);
      });
    }
    let updateArr = {
      updates: updateObj
    }
    this.profileDetailService.postUpdates(updateArr).subscribe(
      event => {
        this.successNotification();
        this.router.navigateByUrl('/profile-updates');
      }

    );
  }
  backToPrevious() {
    this.router.navigateByUrl('/profile-updates');
  }
  successNotification() {
    // Swal.fire( this.successData, 'success')
    Swal.fire({
      icon: 'success',
      text: "Updated Successfully",
    })
  }
  showImg(url) {
    Swal.fire({
      imageUrl: url,
      imageHeight: 400,
      imageWidth: 400,
      showConfirmButton: false
    })
  }
}
