<div class="container-fluid topPad">
  <div>
    <h3>{{componentTitle}}</h3>
  </div>
  <div class="row">
    <div class="col-md-12">

      <div class="example-table-container">
        <form [formGroup]="scheduleUploadForm" autocomplete="off">
          <ng-container formArrayName="scheduleUploads">
            <table mat-table [dataSource]="dataSource" class="example-table" matSort matSortActive="created"
              matSortDisableClear matSortDirection="desc">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.display_name}} </td>
              </ng-container>

              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef class="headerWidth"> Type </th>
                <td mat-cell *matCellDef="let element"> {{element.source_type}} </td>
              </ng-container>

              <ng-container matColumnDef="frequency">
                <th mat-header-cell *matHeaderCellDef class="textCenter"> Frequency </th>

                <td mat-cell *matCellDef="let element;let index = index">
                  <mat-form-field appearance="outline" floatLabel="never">
                    <mat-select required [(ngModel)]="element.frequency" [ngModelOptions]="{standalone: true}">
                      <mat-option *ngFor="let frequency of frequencies" [value]="frequency.value">
                        {{ frequency.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="mailto">
                <th mat-header-cell *matHeaderCellDef class="textCenter"> Mail to </th>
                <td mat-cell *matCellDef="let element">
                  <mat-form-field appearance="outline" class="fit fitToCol">
                    <textarea rows="3" autocomplete="off" class="textArea" matInput [(ngModel)]="element.mail_to"
                      [ngModelOptions]="{standalone: true}"></textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="lastdata">
                <th mat-header-cell *matHeaderCellDef>Last Data </th>
                <td mat-cell *matCellDef="let element">{{element.updated_at | date:'short'}} </td>
              </ng-container>


              <ng-container matColumnDef="lastrun">
                <th mat-header-cell *matHeaderCellDef> Last Run </th>
                <td mat-cell *matCellDef="let element">{{element.last_run | date:'short'}} </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="textCenter"> Action </th>
                <td mat-cell *matCellDef="let element">
                  <div class="row">
                    <div class="col-md-6">
                      <button mat-raised-button color="primary"
                        (click)="onSave(element.mail_to,element.frequency,element.id)">Save</button>
                    </div>
                    <div class="col-md-6">
                      <button mat-raised-button color="primary"
                        (click)="ifFileorApi(element.source_type,element.name)">Run</button>
                    </div>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </ng-container>
        </form>

      </div>
    </div>
  </div>
</div>