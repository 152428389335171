<div class="container-fluid topPad">
  <h1>BATCH LIST</h1>
  <mat-form-field appearance="fill" class="field_pad_right">
    <mat-label>Select Source</mat-label>
    <mat-select (selectionChange)="fetchRecord($event)" [(value)]="selectedOpt">
      <mat-option [value]="0">All</mat-option>
      <mat-option *ngFor="let source of sources" [value]="source.id">{{source.display_name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="field_pad_right">
    <input matInput [ngxMatDatetimePicker]="picker2" placeholder="Choose a Start date"
      (dateChange)="startDateChange($event)" [(ngModel)]="fromDate">
    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker #picker2 >
    </ngx-mat-datetime-picker>
  </mat-form-field>
  <mat-form-field class="field_pad_right">
    <input matInput [ngxMatDatetimePicker]="picker1" placeholder="Choose a End date"
      (dateChange)="endDateChange($event)" [(ngModel)]="endDate">
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker #picker1>
    </ngx-mat-datetime-picker>
  </mat-form-field>
  <div class="row">
    <div class="col-md-12 col-12">
      <div class="faculty-summary-container mat-elevation-z2">
        <table mat-table [dataSource]="dataSource" (matSortChange)="onSortData($event)" matSort>
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell *matCellDef="let element" (click) = "openDetail(element)" class="cursor_pointer"> {{element.id}} </td>
          </ng-container>

          <ng-container matColumnDef="source">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Source </th>
            <td mat-cell *matCellDef="let element"  class="cursor_pointer"> <span (click) = "openDetail(element)"></span>{{element.source}} <img src="../../assets/images/information-circle.svg" (click)="openInfoDialog(element.id)"><span *ngIf="element.source == 'bulk'"><img src="../../assets/images/check-square.svg" (click)="bulkAlert(element.id)" *ngIf="!element.results.auto_accepted"><img src="../../assets/images/check-grey.svg" *ngIf="element.results.auto_accepted"></span></td>
          </ng-container>

          <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ran On </th>
            <td mat-cell *matCellDef="let element" (click) = "openDetail(element)" class="cursor_pointer"> {{element.created_at | date:'short'}} </td>
          </ng-container>

          <ng-container matColumnDef="imported">
            <th mat-header-cell *matHeaderCellDef> Imported </th>
            <td mat-cell *matCellDef="let element"><span *ngIf="element.results"><span
                  *ngIf="element.results.import_results" (click) = "openDetail(element)" class="cursor_pointer"> {{element.results.import_results.total}} </span></span></td>
          </ng-container>
          <ng-container matColumnDef="errored">
            <th mat-header-cell *matHeaderCellDef> Errored </th>
            <td mat-cell *matCellDef="let element"><span *ngIf="element.results"><span
                  *ngIf="element.results.import_results" (click) = "openDetail(element)" class="cursor_pointer"> {{element.results.import_results.errored}} </span></span></td>
          </ng-container>
          <ng-container matColumnDef="saved">
            <th mat-header-cell *matHeaderCellDef> Saved </th>
            <td mat-cell *matCellDef="let element"><span *ngIf="element.results"><span
                  *ngIf="element.results.import_results" (click) = "openDetail(element)" class="cursor_pointer">{{element.results.import_results.saved}}</span> </span> </td>
          </ng-container>
          <ng-container matColumnDef="success">
            <th mat-header-cell *matHeaderCellDef> Successful Match </th>
            <td mat-cell *matCellDef="let element"><span *ngIf="element.results" class="cursor_pointer"><span
                  *ngIf="element.results.recon_results" (click) = "openDetail(element)">{{element.results.recon_results.RECON_SUCCESSFUL}}</span></span>
            </td>
          </ng-container>
          <ng-container matColumnDef="multiple">
            <th mat-header-cell *matHeaderCellDef> Multiple Match </th>
            <td mat-cell *matCellDef="let element" class="cursor_pointer"><span *ngIf="element.results"><span
                  *ngIf="element.results.recon_results" (click) = "openDetail(element)">{{element.results.recon_results.RECON_MULTIPLE_MATCH}}</span>
              </span> </td>
          </ng-container>
          <ng-container matColumnDef="nomatch">
            <th mat-header-cell *matHeaderCellDef> No Match </th>
            <td mat-cell *matCellDef="let element" class="cursor_pointer"><span *ngIf="element.results"><span
                  *ngIf="element.results.recon_results" (click) = "openDetail(element)">{{element.results.recon_results.RECON_NO_MATCH}} </span></span>
            </td>
          </ng-container>
          <ng-container matColumnDef="pending">
            <th mat-header-cell *matHeaderCellDef> Pending Verification </th>
            <td mat-cell *matCellDef="let element" class="cursor_pointer"><span *ngIf="element.results"><span
                  *ngIf="element.results.recon_results" (click) = "openDetail(element)">{{element.results.recon_results.RECON_PENDING_VERIFICATION}}</span>
              </span> </td>
          </ng-container>



          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;">
          </tr>
        </table>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <mat-paginator  [pageSize]="rowsPerPage"
        showFirstLastButtons [length]="totalElem" (page)="onPagination($event)" showFirstLastButtons
        [pageIndex]="batchIndex" id="admin-audit-history-pagination"></mat-paginator>
    </div>
  </div>
</div>