import { Component, OnInit, ViewChild } from '@angular/core';
import { EmailValidator, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SearchPersonDialogComponent } from '../search-person-dialog/search-person-dialog.component';
import { PaginationParam, PersonSearch, SearchParam } from './search-person';
import { SearchPersonService } from './search-person.service';

@Component({
  selector: 'app-search-person',
  templateUrl: './search-person.component.html',
  styleUrls: ['./search-person.component.css']
})
export class SearchPersonComponent implements OnInit {
 
  resultsLength = 0;
  pageSizeOptions: number[] = [];
  rowsPerPage: number = 20;
  totalElem : number = 5;
  pageNumber: number = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['firstname', 'lastname', 'email', 'salesforceid','emplid', 'advanceid', 'imodulusid', 'questid'];
  dataSource = new MatTableDataSource<PersonSearch[]>();
  popupData: any;
  searchParams: SearchParam = new SearchParam();
  searchForm: UntypedFormGroup
  
  constructor(
    public dialog: MatDialog,
    public searchPersonService: SearchPersonService,
    public fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {

    this.searchForm = this.fb.group({
      firstName: new UntypedFormControl(null),
      lastName: new UntypedFormControl(null),
      salesforceId: new UntypedFormControl(null),
      emplId: new UntypedFormControl(null),
      advanceId: new UntypedFormControl(null),
      email: new UntypedFormControl(null),
      imodulesId: new UntypedFormControl(null),
      careerQuestId: new UntypedFormControl(null),
    })


    let params: PaginationParam = new PaginationParam(1, this.rowsPerPage);
    this.getPeople(params);

  }
  pageSizeOptionsCalculation(total: number) {
    const defaultPageArray: number[] = [5, 10, 25, 50, 100, 250, 500, total];
    this.pageSizeOptions = [];
    this.pageSizeOptions = defaultPageArray.filter(x => x <= total);
}
onPagination(event: any) {
  this.pageNumber = event.pageIndex + 1;
  let params: PaginationParam = new PaginationParam(this.pageNumber, this.paginator.pageSize);
  this.getPeople(params);
}

getPeople(params) {
    this.searchPersonService.getPersonData(params).subscribe(data => {
    this.dataSource = new MatTableDataSource(data.ia_people);
    this.totalElem = data.totalCount;
    this.pageSizeOptionsCalculation(data.totalCount);
  })
}

getPeopleSearch(){
  let pagination: PaginationParam = new PaginationParam(1, this.rowsPerPage);
  this.searchPersonService.getSearchPerson(this.searchParams, pagination).subscribe(data => {
  this.dataSource = new MatTableDataSource(data.ia_people);
  this.totalElem = data.totalCount;
  this.pageSizeOptionsCalculation(data.totalCount)
})
}
navSales(element){
  if(element.salesforce_id){
    let url = 'https://umuc.lightning.force.com/lightning/r/Contact/'+element.salesforce_id+'/view'
  window.open(url, "_blank");
  }
  
}
getProfile(element){
this.searchPersonService.getPersonProfile(element.salesforce_id).subscribe(data => {
  this.popupData = data.ia_people;
  const dialogRef = this.dialog.open(SearchPersonDialogComponent, {
    width: '750px',
    data: this.popupData
  });
  
  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
  });
})

}

}
